@font-face {
  font-family: "robotoregular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../fonts/roboto-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "robotomedium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/roboto-medium-webfont.woff2") format("woff2"),
    url("../fonts/roboto-medium-webfont.woff") format("woff");
}

@font-face {
  font-family: "robotobold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("../fonts/roboto-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "ubunturegular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/ubuntu-regular-webfont.woff2") format("woff2"),
    url("../fonts/ubuntu-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "ubuntumedium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/ubuntu-medium-webfont.woff2") format("woff2"),
    url("../fonts/ubuntu-medium-webfont.woff") format("woff");
}

@font-face {
  font-family: "ubuntubold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/ubuntu-bold-webfont.woff2") format("woff2"),
    url("../fonts/ubuntu-bold-webfont.woff") format("woff");
}
