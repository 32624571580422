.error-wrapper {
  background-color: var(--red-2);
  display: block;
  padding: 5px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99;
}

.error-message {
  color: var(--white);
  font-family: "ubuntubold";
  font-size: 12px;
}

.close-error-message {
  color: var(--white);
  float: right;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  text-decoration: none;
}
