.smart-table {
  font-size: 12px;
  height: 385px;
  overflow: scroll;
  width: 100%;

  table {
    background-color: var(--white);
    font-family: "robotobold";
    font-size: 11px;
    width: 100%;

    th {
      background-color: var(--gray-4);
      color: var(--white);
      padding: 10px;
      text-align: left;
      white-space: nowrap;
    }

    td {
      padding: 10px;
      white-space: nowrap;
    }

    tr {
      &:nth-child(odd) {
        background-color: var(--gray-1);
      }
    }
  }
}

.smart-table-container-scroll {
  overflow-x: scroll;
  width: 100%;
}

#performance-table {
  height: 400px;
  width: 640px;
}

.smart-table-footer-container {
  background-color: var(--green-3);
  border-radius: 0 0 10px 10px;
  display: block;
  height: 26px;

  @media (--sm-viewport) {
    background-color: var(--green-3);
    height: auto;
    padding: 5px;
  }
}

.smart-table-download-container {
  float: right;
  margin-right: 20px;

  @media (--sm-viewport) {
    float: none;
    margin: 0;
    text-align: center;
  }

  .smart-table-download-icon {
    background-image: url("../images/icon-download.png");
    display: inline-block;
    height: 12px;
    margin-right: 5px;
    width: 9px;
  }

  .smart-table-download-icon-disabled {
    background-image: url("../images/icon-download.png");
    display: inline-block;
    height: 12px;
    opacity: 0.73;
    margin-right: 7px;
    width: 9px;
  }

  .smart-table-filtered-download-text-disabled {
    color: var(--gray-4);
    cursor: default;
    font-family: "ubuntumedium";
    font-size: 11px;
    pointer-events: none;
  }

  .smart-table-filtered-download-text {
    color: var(--white);
    font-family: "ubuntumedium";
    font-size: 11px;
  }

  .smart-table-download-text {
    color: var(--white);
    font-family: "ubuntumedium";
    font-size: 11px;
  }

  .spinner {
    margin-top: 3px;
  }
}

.smart-table-footer-text {
  color: var(--white);
  float: left;
  font-family: "ubuntumedium";
  font-size: 11px;
  margin-left: 20px;
  margin-top: 5px;

  @media (--sm-viewport) {
    display: table;
    float: none;
    margin: 0 auto;
  }
}

.limit-message-block {
  margin-left: 10px;
  margin-top: 5px;
}

.table-text-container {
  margin-top: 10px;
}

.question-mark-icon {
  background-image: url("../images/icon-question-mark.png");
  display: inline-block;
  height: 18px;
  margin-right: 5px;
  vertical-align: super;
  width: 18px;

  @media (--sm-viewport) {
    margin-top: 4px;
    vertical-align: top;
  }
}

.table-footer-limit-text {
  display: inline-block;
  font-size: 11px;
  width: 410px;

  @media (--sm-viewport) {
    width: 250px;
  }
}

.smart-table-filtered-download-container {
  margin-right: 20px;

  @media (--sm-viewport) {
    margin-right: 10px;
  }
}

.ml-20 {
  margin-left: 20px;
}
