.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto;
}

.spacer {
  background-color: var(--gray-3);
  display: flex;
  flex-grow: 1;
  height: 1px;
  vertical-align: middle;
  width: auto;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-normal {
  text-transform: none;
}

.block-element {
  display: block;
}

.inline-block-element {
  display: inline-block;
}
