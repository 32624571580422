html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  min-height: 100%;
  text-rendering: optimizeLegibility;
}

body {
  background-color: var(--white);
  color: var(--text-color);
  font-family: "ubunturegular";
  font-size: 16px;
  height: 100%;
  line-height: 1.5;
}

#main {
  background-color: var(--gray-2);
  height: 100%;
  overflow: auto;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-1 { flex: 1 }
.flex-2 { flex: 2 }
.flex-3 { flex: 3 }
.flex-4 { flex: 4 }
.flex-5 { flex: 5 }
.flex-6 { flex: 6 }

