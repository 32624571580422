:root {
  --text-color: #444;
  --red-1: #e50b05;
  --red-2: #e40a04;
  --blue-1: #003d4e;
  --blue-2: #393939;
  --blue-3: #013341;
  --blue-4: #02556c;
  --orange: #ed9224;
  --white: #fff;
  --black: #000;
  --gray-1: #f5f4f4;
  --gray-2: #ededed;
  --gray-3: #ddd;
  --gray-4: #c1c0c0;
  --gray-5: #60606a;
  --gray-8: #333333;
  --gray-9: #212121;
  --green-1: #05d3c8;
  --green-2: #1b9290;
  --green-3: #01475b;
  --green-4: #233e4f;
  --green-5: #00c89d;
  --purple-1: rgb(178,0,255);
  --socioemotional-yellow: #f29648;
  --socioemotional-orange: #ed7a4c;
  --socioemotional-pink: #e6508c;
  --pdf-margin-vertical: 0.4cm;
  --pdf-margin-horizontal: 0.5cm;
}

@custom-media --xs-viewport (width >= 767px);
@custom-media --sm-viewport (width <= 768px);
@custom-media --md-viewport (width <= 992px);
@custom-media --lg-viewport (width <= 1200px);
