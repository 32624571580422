.tooltip {
  position: relative;

  & :hover {
    & ::after {
      align-items: center;
      background-color: var(--white);
      box-shadow: -3px 0 4px var(--gray-4);
      color: var(--text-color);
      content: attr(title);
      display: flex;
      font-family: "ubuntumedium";
      font-size: 11px;
      height: 62px;
      justify-content: center;
      left: -5px;
      padding: 15px 0;
      position: absolute;
      text-align: center;
      text-transform: none;
      top: -75px;
      width: 89px;
    }

    & ::before {
      border-color: transparent var(--white) transparent transparent;
      border-style: solid;
      border-width: 10px 15px 10px 0;
      content: "\A";
      left: 32px;
      position: absolute;
      top: -20px;
      transform: rotate(-90deg);
      z-index: 10;
    }
  }
}

.tooltip-big {
  &:hover {
    &::after {
      border: 1px solid var(--gray-4);
      bottom: 90%;
      height: auto;
      left: 50px;
      top: auto;
      white-space: pre-line;
    }

    &::before {
      background: var(--white);
      border-bottom: 1px solid var(--gray-4);
      border-right: 1px solid var(--gray-4);
      bottom: 68%;
      content: "";
      display: block;
      height: 10px;
      left: 90px;
      position: absolute;
      top: 5px;
      transform: rotate(45deg);
      width: 10px;
    }
  }
}
