.main-pdf-screen {
  background-color: var(--gray-8);
  width: 793px;
  min-width: 793px;
  max-width: 793px;
  min-width: 793px;
  height: 1096px;
  max-height: 1096px;
}

.pdf-A4-page {
  background-color: var(--gray-2);
  font-family: 'robotoregular';
  width: 793px;
  min-width: 793px;
  max-width: 793px;
  min-width: 793px;
  height: 1096px;
  max-height: 1096px;
  box-shadow: 0 10px 20px var(--gray-9);
  display: block;
}
.container-pdf-header {
  display: flex;
  justify-content: center;
  margin: var(--pdf-margin-vertical) var(--pdf-margin-horizontal) 0;
}

.section-pdf-logo {
  height: 2cm;
  background-image: url("../images/logo-avaliação-socioemocional.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.section-pdf-logo + div {
  width: fit-content;
  text-align: right;
  font-weight: 600;
  line-height: 1.2;
  margin: 0.1cm auto;
}
.input-box {
  border: 2px solid;
  border-radius: 0.15cm;
  padding: 0.1cm 0.6cm;
  margin: 0.05cm 0.4cm 0.2cm 0;
  height: 0.825cm;
}

.font-size-050 { font-size: 0.50cm; }
.font-size-045 { font-size: 0.45cm; }
.font-size-040 { font-size: 0.40cm; }
.font-size-035 { font-size: 0.35cm; }
.font-size-032 { font-size: 0.32cm; }
.font-size-030 { font-size: 0.30cm; }
.font-size-025 { font-size: 0.25cm; }
.font-size-020 { font-size: 0.20cm; }
.italic { font-style: italic; }
.bold { font-weight: bold; }
.line-height-fix { line-height: 1.2;  }
.mt-01 { margin-top: 0.1cm; }

.section-primary-results {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.8cm;
}

.rounded-rectangle-alt {
  background-image: linear-gradient(
    -90deg, var(--socioemotional-yellow), var(--socioemotional-orange), var(--socioemotional-pink)
  );
  border-radius: 0.4cm;
  height: 3cm;
  flex: 1;
  margin: 0 0.12cm;
}
.rounded-rectangle-alt-inner {
  background-color: var(--gray-2);
  border-radius: 0.35cm;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  padding: 0 0.6cm;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.svg-size-md {
  min-width: 0.8cm;
  min-height: 0.8cm;
}
.svg-fill-pink { fill: var(--socioemotional-pink) }
.svg-fill-orange { fill: var(--socioemotional-orange) }
.svg-fill-yellow { fill: var(--socioemotional-yellow) }

.svg-stroke-pink { stroke: var(--socioemotional-pink) }
.svg-stroke-orange { stroke: var(--socioemotional-orange) }
.svg-stroke-yellow { stroke: var(--socioemotional-yellow) }

.color-white { color: var(--white) }
.color-pink { color: var(--socioemotional-pink) }
.color-orange { color: var(--socioemotional-orange) }
.color-yellow { color: var(--socioemotional-yellow) }

.section-abilities-results {
  margin: 0.1cm 0.7cm 0.1cm 0.8cm;
  letter-spacing: 0.3px;
  line-height: 1.25;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 15cm;
  color: var(--gray-9);
}
.section-abilities-results hr {
  height: 1px;
  margin-bottom: 5px;
  background: var(--black);
}


.section-summary-results {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.8cm;
}

.section-summary-results .rounded-rectangle-alt {
  height: 5cm;
  border: 2px solid var(--white);
  border-radius: 0.8cm;
}

.section-summary-results .rounded-rectangle-alt hr {
  background: var(--white);
  border-color: var(--white);
  border-style: solid;
}

.footer-links-info {
  background-image: linear-gradient(
    -90deg, var(--socioemotional-yellow), var(--socioemotional-orange), var(--socioemotional-pink)
  );
  color: var(--white);
  margin-top: 10px;
  height: 2.5cm;
  padding: 0 0.5cm;
  display: flex;
  align-items: center;
}
.radius-card-top {
  border-radius: 0.8cm 0.8cm 0 0;
}
.bg-pink {
  background-color: var(--socioemotional-pink);
}
.bg-yellow {
  background-color: var(--socioemotional-yellow);
}
.font-weight-regular {
  font-weight: 300;
}
.letter-spacing-md {
  letter-spacing: 1px;
}
.footer-links {
  display: grid;
  grid-template-columns: auto auto auto;
}
.footer-links a {
  display: flex;
  align-items: center;
  font-size: 0.3cm;
  margin-right: 5px;
}
.footer-links svg {
  fill: var(--white)
}
.footer-links .svg-badge {
  background: var(--socioemotional-pink);
  width: 0.5cm;
  height: 0.5cm;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1cm;
  margin: 3px 5px;
}
.footer-links svg.instagram {
  stroke: var(--white);
  fill: transparent;
}
.logo-white {
  background-image: url("../images/logo_white.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 1.3cm;
  height: 1.5cm;
  margin-bottom: 0.2cm;
}
.logo-white + div {
  font-size: 0.5cm;
  font-family: 'robotobold';
}
.logo-white + div span {
  font-size: 0.25cm;
  vertical-align: text-top;
}
.qr-code {
  background-image: url("../images/socio_game_qr_familia_site.png");
  width: 1.25cm;
  height: 1.25cm;
  border-radius: 0.2cm;
  background-size: contain;
  margin-left: 0.2cm;
}
.upper-case {
  text-transform: uppercase;
}
