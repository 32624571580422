footer {
  background-color: var(--white);
  font-size: 9px;
}

.copyright-footer {
  vertical-align: middle;
  min-width: 278px;
}

.logo-footer {
  background-image: url("../images/logo-escola360-footer.png");
  display: inline-block;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
  min-width: 16px;
}

.logo-mindzup {
  background-image: url("../images/app-logo.svg");
  background-size: 100%;
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  width: 126px;
  margin-left: 15px;  
}

.logo-mindlab {
  background-image: url("../images/Logo_MindLab.png");
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  height: 27px;
  width: 95px;
  margin-left: 15px;  
}

.logo-mindlabNet {
  background-image: url("../images/logo-plataforma_professor.png");
  background-size: 100%;
  display: inline-block;
  height: 27px;
  vertical-align: middle;
  width: 95px;
  border-radius: 5px;
}
