.mobile-menu-container {
  background-color: var(--blue-1);
  color: var(--white);
  height: 100%;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 226px;
  z-index: 9999;
}

.menu-button {
  @media (--sm-viewport) {
    background-image: url("../images/icon-menu.png");
    display: inline-block;
    height: 17px;
    width: 23px;
  }
}

.mobile-menu-header {
  background-color: var(--blue-3);
  border-bottom: 1px solid var(--blue-4);
  padding: 11px 15px;
}

.mobile-menu-title {
  font-family: "ubuntubold";
  font-size: 13px;
  text-transform: uppercase;
}

.mobile-menu-close {
  font-family: "ubuntumedium";
  font-size: 15px;
}

.mobile-menu-option {
  background-color: var(--blue-1);
  border-bottom: 1px solid var(--blue-4);
  font-size: 12px;
  padding: 10px 15px;
}

.mobile-menu-logout {
  background-color: var(--blue-3);
  padding: 10px 15px;
}

.mobile-menu-arrow-right {
  background-image: url("../images/icon-arrow-right.png");
  display: inline-block;
  height: 9px;
  width: 8px;
}

.mobile-menu-school-name {
  font-size: 13px;
}

.mobile-menu-text {
  color: var(--white);
  font-size: 12px;
}

.mobile-menu-school-info {
  background-color: var(--blue-3);
  padding: 15px;
  text-align: center;
}
