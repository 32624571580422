.flex-element {
  display: flex;
}

.grid-outer {
  flex: 1 0 auto;
  padding: 30px;
  width: 100%;
}

.grid-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 2%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid-cell {
  flex: 1;
}

.grid-flex-cells {
  > .grid-cell {
    display: flex;
  }
}

.grid-flex {
  display: flex;

  @media (--sm-viewport) {
    display: block;
  }
}

.grid-top {
  align-items: flex-start;
}

.grid-bottom {
  align-items: flex-end;
}

.grid-center {
  align-items: center;
}

.grid-justify-center {
  justify-content: center;
}

.grid-justify-between {
  justify-content: space-between;
}

.grid-cell-top {
  align-self: flex-start;
}

.grid-cell-bottom {
  align-self: flex-end;
}

.grid-cell-center {
  align-self: center;
}

.grid-cell-autosize {
  flex: none;
}

.grid-fit {
  > .grid-cell {
    display: 1;

    @media (--md-viewport) {
      flex: 1;
    }
  }
}

.grid-double {
  justify-content: space-between;

  > .grid-cell {
    flex: none;
    width: 49%;

    @media (--md-viewport) {
      width: 98%;
    }
  }
}

.grid-thirds {
  justify-content: space-between;

  > .grid-cell {
    flex: none;
    width: 32%;

    @media (--sm-viewport) {
      width: 98%;
    }
  }
}

.grid-gutters-paddding {
  > .grid-cell {
    padding: 10px;
  }
}

.grid-gutters-margin {
  > .grid-cell {
    margin: 10px 0;
  }
}
