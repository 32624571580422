.main-title {
  color: var(--green-3);
  display: inline-block;
  flex-grow: 0;
  font-family: "ubuntumedium";
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
  text-transform: uppercase;
}
