.login-container {
  height: 100%;
  background-color: var(--green-4);
  font-family: "ubunturegular";
}

.logo-escola-360-login {
  background-image: url("../images/logo-escola-360-login.png");
  display: block;
  height: 34px;
  margin: 0 auto;
  width: 211px;
}

.login-form-arrow-icon {
  background-image: url("../images/login-arrow-icon.png");
  display: inline-block;
  height: 488px;
  position: relative;
  width: 477px;

  @media (--sm-viewport) {
    background-image: none;
  }
}

.login-form {
  background-color: var(--white);
  border: 10px solid var(--gray-2);
  border-radius: 50%;
  height: 430px;
  left: 15px;
  padding: 80px 40px;
  position: absolute;
  text-align: center;
  top: 30px;
  width: 430px;

  @media (--sm-viewport) {
    border-radius: 0%;
    height: auto;
    left: auto;
    position: relative;
    top: auto;
    width: 100%;
  }

  .grid-flex {
    align-items: center;
  }

  label {
    font-family: "ubuntubold";
    font-size: 13px;
    line-height: 15px;
    margin-right: 15px;
    text-align: right;
    width: 25%;

    @media (--sm-viewport) {
      margin-right: 0;
    }
  }

  small {
    display: block;
    font-family: "ubunturegular";

    @media (--sm-viewport) {
      margin-bottom: 5px;
      text-align: center;
    }

    a {
      color: inherit;
    }
  }
}

.login-input-text {
  background-color: var(--gray-2);
  font-family: inherit;
  font-size: 12px;
  padding: 5px;
  width: 100%;
}

.login-footer {
  color: var(--blue-2);
  font-size: 12px;
}
