.collapsible--trigger {
  background-color: var(--green-3);
  border-radius: 0 0 10px 10px;
  border-top: 5px solid var(--white);
  display: block;
  height: 16px;
  text-align: center;

  .expand-btn {
    display: block;
    height: 23px;
    margin: 0 auto;
    margin-top: -4px;
    width: 23px;
  }

  &.is-closed {
    .expand-btn {
      background-image: url("../images/icon-expand.png");
      cursor: pointer;
    }
  }

  &.is-open {
    .expand-btn {
      background-image: url("../images/icon-contract.png");
      cursor: pointer;
    }
  }
}
