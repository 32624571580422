.back-button {
  background-image: url("../images/icon-back.png");
  display: inline-block;
  flex-grow: 0;
  height: 19px;
  vertical-align: middle;
  width: 19px;
}

.btn {
  border: 0 none;
  border-radius: 10px;
  cursor: pointer;
  font-family: "ubuntubold";
  font-size: 14px;
  padding: 10px 40px;
  text-transform: uppercase;
}

.btn-primary {
  background-color: var(--green-5);
  color: var(--white);

  & :hover {
    background-color: var(--green-2);
  }
}
