.product-element {
  margin-bottom: 30px;
}

.product-element-title {
  background-color: var(--green-3);
  border-radius: 10px 10px 0 0;
  display: inline-block;
  padding: 0 15px;

  &.with-filter {
    display: flex;
  }

  h2 {
    color: var(--white);
    flex: 1;
    font-family: "robotomedium";
    font-size: 12px;
    line-height: 27px;
  }
}

.product-element-footer {
  background-color: var(--green-3);
  border-radius: 0 0 10px 10px;
  height: 10px;
  margin: -30px 0 30px;
}

.product-box {
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--gray-2);
  border-bottom-width: 8px;
  border-radius: 5px;
  display: flex;
  height: 150px;
  justify-content: center;
  margin-bottom: 6%;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 30%;
  min-width: 125px;
  min-height: max-content;


  @media (--sm-viewport) {
    width: 48%;
  }

  &.orange-border {
    border-bottom-color: var(--orange);
  }

  &.green-border {
    border-bottom-color: var(--green-2);
  }

  &.blue-border {
    border-bottom-color: var(--blue-1);
  }

  &.red-border {
    border-bottom-color: var(--red-2);
  }

  &.purple-border {
    border-bottom-color: var(--purple-1);
  }  

  &.unavailable {
    border-bottom-color: var(--gray-4);
    display: block;
    padding: 15px;
  }

  &.soon {
    background-color: transparent;
    border: 1px dashed var(--gray-4);
    color: var(--gray-4);
    display: block;
    font-size: 12px;
    padding: 50px;
  }
}

.product-box-icon {
  display: block;
  height: 50px;
  margin: 0 auto 5px;
  width: 50px;

  &.profile {
    background-image: url("../images/icon-profile.png");
  }

  &.daily-training {
    background-image: url("../images/icon-daily-training.png");
  }

  &.test {
    background-image: url("../images/icon-test.png");
  }

  &.consulting-analysis {
    background-image: url("../images/icon-consulting-analysis.png");
  }

  &.test-unavailable {
    background-image: url("../images/icon-test-unavailable.png");

    @media (--sm-viewport) {
      display: none;
    }
  }

  &.consulting-analysis-unavailable {
    background-image: url("../images/icon-consulting-analysis-unavailable.png");

    @media (--sm-viewport) {
      display: none;
    }
  }

  &.exercise-list {
    background-image: url("../images/icon-exercise-list.png");
  }

  &.exercise-list-unavailable {
    background-image: url("../images/icon-exercise-list-unavailable.png");
    height: 33px;
    width: 37px;

    @media (--sm-viewport) {
      display: none;
    }
  }

  &.socioemocional {
    background-image: url("../images/icon-socioemocional.svg");
    background-size: cover;
  }
}

.product-box-label {
  color: var(--blue-1);
  display: inline-block;
  font-family: "ubuntubold";
  font-size: 12px;
  text-transform: uppercase;

  &.unavailable {
    color: var(--gray-4);
  }
}

.product-box-unavailable-text {
  color: var(--gray-4);
  display: block;
  font-size: 10px;
}

.product-box-link {
  border-radius: 50%;
  bottom: -20px;
  display: inline-block;
  height: 30px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  width: 30px;

  .product-box-link-icon {
    background-image: url("../images/icon-point.png");
    display: inline-block;
    height: 13px;
    vertical-align: middle;
    width: 8px;
  }

  &.orange-link {
    background-color: var(--orange);
  }

  &.green-link {
    background-color: var(--green-2);
  }

  &.blue-link {
    background-color: var(--blue-1);
  }

  &.red-link {
    background-color: var(--red-2);
  }

  &.unavailable {
    background-color: var(--gray-4);
  }

  &.purple-link {
    background-color: var(--purple-1);
  }
}
