header {
  background-color: var(--blue-1);
  border-bottom: 2px solid var(--white);
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.logo-escola360 {
  background-image: url("../images/logo-escola360.png");
  display: inline-block;
  height: 14px;
  width: 89px;
}

.menu-icon {
  border-bottom: 2px solid var(--white);
  border-top: 2px solid var(--white);
  display: none;
  height: 16px;
  margin-left: 20px;
  position: relative;
  width: 23px;

  & ::before {
    border-top: 2px solid var(--white);
    content: "";
    left: 0;
    position: absolute;
    top: 5px;
    width: 100%;
  }
}

.btn-logout {
  background-color: var(--green-3);
  color: var(--white);
  cursor: pointer;
  font-size: 12px;
  padding: 13px 30px;
  position: relative;
  right: 0;
  text-decoration: none;

  & :hover {
    text-decoration: underline;
  }

  @media (--sm-viewport) {
    display: none;
  }
}

.header-filters {
  background-color: var(--green-2);
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 99;

  .filter {
    display: inline-block;
    margin-right: 20px;
    position: relative;

    @media (--sm-viewport) {
      display: block;
      margin-bottom: 5px;
    }

    label {
      color: var(--white);
      font-size: 13px;
      margin-right: 5px;

      @media (--sm-viewport) {
        display: inline-block;
        text-align: right;
        width: 50px;
      }
    }

    select {
      font-family: "ubuntumedium";
      font-size: 13px;

      @media (--sm-viewport) {
        width: 60%;
      }
    }

    .range-filter {
      input {
        @media (--sm-viewport) {
          width: 100%;
        }
      }
    }
  }
}

.input-filter {
  border: 1px solid var(--gray-3);
  border-radius: 5px;
  font-family: "ubunturegular";
  font-size: 12px;
  min-width: 160px;
  padding: 3px 25px 3px 10px;

  @media (--sm-viewport) {
    width: 60%;
  }
}

.input-filter-icon {
  background-image: url("../images/icon-search.png");
  display: block;
  height: 13px;
  position: absolute;
  right: 4px;
  top: 6px;
  width: 16px;

  @media (--sm-viewport) {
    display: none;
  }
}

.school-user-dropdown-content {
  background-color: var(--white);
  font-size: 13px;
  left: 42px;
  line-height: 16px;
  min-width: 156px;
  padding: 0 10px;
  position: absolute;
  text-align: left;

  li {
    margin-bottom: 2px;
    padding: 3px 0;

    & :hover {
      cursor: pointer;
      font-family: "ubuntumedium";
    }
  }
}
