.badge-label {
  font-size: 11px;
  line-height: 8px;
}

.badge {
  color: var(--green-3);
  flex: 1;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.badge-icon-bg {
  background-color: var(--white);
  border: 1px solid var(--green-2);
  border-radius: 50%;
  height: 70px;
  margin: 0 auto;
  width: 70px;
}

.badge-count {
  font-family: "ubuntubold";
}

.badge-label {
  font-size: 11px;
  line-height: 8px;
}

.badge-container {
  background-color: var(--white);
  border-radius: 10px;
  text-align: left;
}

.badge-container-count {
  display: block;
  font-family: "ubuntubold";
  font-size: 50px;
  line-height: 50px;
}

.badge-container-label {
  display: block;
  font-family: "ubuntumedium";
  font-size: 16px;
  text-transform: uppercase;
}

.chart-container-small-label {
  font-size: 11px;
  text-align: center;
}

.school-profile-badge {
  min-height: 350px;
  padding: 20px;

  @media (--sm-viewport) {
    min-height: 0;
  }
}

.school-profile-badge-icon {
  background-image: url("../images/icon-school.png");
  display: block;
  height: 86px;
  margin: 0 auto 10px;
  width: 86px;
}

.school-profile-badge-label {
  color: var(--green-3);
  font-family: "robotobold";
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.school-profile-badge-text {
  font-size: 12px;

  .bold {
    font-family: "ubuntumedium";
  }

  small {
    line-height: 10px;
  }
}

.licences-activations-badge-icon {
  background-image: url("../images/icon-students.png");
  display: inline-block;
  height: 80px;
  margin-right: 30px;
  width: 57px;
}

.daily-mission-answered-badge-icon {
  background-image: url("../images/icon-check.png");
  display: inline-block;
  height: 43px;
  margin-right: 30px;
  margin-bottom: 50px;
  width: 41px;
}

.daily-mission-answered-badge {
  padding: 19px 10px 4px 10px;
}

.licences-activations-badge,
.paying-students-badge,
.finalized-exams-badge {
  padding: 19px 10px 28px 10px;
}

.finalized-exams-badge-icon {
  background-image: url("../images/icon-accelerometer.png");
  display: inline-block;
  height: 39px;
  margin-right: 30px;
  width: 81px;
}

.paying-students-badge-icon {
  background-image: url("../images/icon-paying-students.png");
  display: inline-block;
  height: 44px;
  margin-right: 30px;
  margin-bottom: 40px;
  width: 56px;
}
